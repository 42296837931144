<template>
  <div>
    <!--begin::Assessment-->
    <div class="card card-custom gutter-b">
      <div class="card-header">
        <div class="card-title">
          <h3 class="card-label">{{ page_header }}</h3>
        </div>
        <div class="card-toolbar">
          <div class="example-tools justify-content-center">
            <div class="my-2">
              <!-- <button
                type="button"
                class="mt-3 mr-1 mb-3 v-btn v-btn--depressed v-btn--flat v-btn--outlined theme--light v-size--default indigo--text"
              >
                <span class="v-btn__content" @click="redirectToAssessmentList()">
                  Back
                </span>
              </button> -->
            </div>
          </div>
        </div>
      </div>
      <div class="card-body">
        <form class="form" novalidate="novalidate" id="kt_assessment_form">
          <!--begin::Example-->
          <div
            class="example justify-content-center"
            v-if="pre_assessment_info && !show_register_page"
          >
            <h3>Assessment Information</h3>
            <br/>
            <div v-html="pre_assessment_info" style="font-size: 16px;"></div>
            <v-row fluid class="card-footer pl-3 mt-3">
              <v-btn
                color="success"
                class="mr-4"
                ref="kt_store_submit"
                @click="startAssessment(0)"
              >
                Start Assessment
              </v-btn>
            </v-row>
          </div>
          <div class="example" v-else-if="!complete_assessment && question && !show_register_page">
            <v-form ref="form" v-model="valid" lazy-validation>
              <v-row no-gutters>
                <v-col cols="12" sm="12"> {{ order }}. {{ question }} </v-col>
              </v-row>
              <v-row no-gutters v-if="is_informational==0">
                <v-radio-group v-model="selected_option">
                  <v-col cols="12" sm="4">
                    <v-radio
                      class="color-purple"
                      :label="option1.option_value"
                      :value="option1"
                    ></v-radio>
                  </v-col>
                  <v-col cols="12" sm="4">
                    <v-radio
                      class="color-purple"
                      :label="option2.option_value"
                      :value="option2"
                    ></v-radio>
                  </v-col>
                  <v-col cols="12" sm="4" v-if="is_NA==1">
                    <v-radio
                      class="color-purple"
                      :label="option3.option_value"
                      :value="option3"
                    ></v-radio>
                  </v-col>
                </v-radio-group>
              </v-row>
              <v-row no-gutters v-else>
                <v-col cols="12" sm="6">
                  <v-text-field
                    class="pr-2"
                    v-model="option_value"
                    label="Answer"
                    required
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
              <h5 class="mt-3 mb-3">
                Completed ({{ order - 1 }}/{{ number_of_questions }})
              </h5>
              <b-progress :max="max_progress">
                <b-progress-bar
                  :value="current_progress"
                  :label="
                    `${((current_progress / max_progress) * 100).toFixed(2)}%`
                  "
                >
                </b-progress-bar>
              </b-progress>

              <v-row fluid class="card-footer pl-3 mt-3">
                <div class="col-md-4">
                  <v-btn
                    v-if="!view_submit"
                    color="success"
                    class="mr-4"
                    ref="kt_store_submit"
                    @click="validateAnswer"
                  >
                    Next
                  </v-btn>
                  <v-btn
                    v-else-if="view_submit && !view_ok_next"
                    color="success"
                    class="mr-4"
                    ref="kt_store_submit"
                    @click="submitAssessment"
                  >
                    Submit
                  </v-btn>
                  <v-btn
                    v-else
                    color="success"
                    class="mr-4"
                    ref="kt_store_submit"
                    @click="postInfoViewed"
                  >
                    Ok!
                  </v-btn>
                </div>
                <div class="col-md-8 text-right">
                  <!--v-btn
                    v-if="!is_first_question"
                    color="warning"
                    dark
                    @click="saveAndExitAssessment()"
                  >
                    Save and Exit Assessment
                  </v-btn -->
                  <v-btn
                    class="ml-5"
                    color="error"
                    @click="cancelAndExitAssessment()"
                    dark
                  >
                    Cancel and Exit Assessment
                  </v-btn>
                </div>
              </v-row>
            </v-form>
          </div>
          <div class="example justify-content-center" v-else-if="complete_assessment && !show_register_page">
            <h3 style="text-align: center;">Post Assessment Information</h3>
            <h6>Score Risk : 

            <b v-if="score_interpretation_label == 'Low Risk'" class="green-font">{{ score_interpretation_label }}</b>
              
            <b v-if="score_interpretation_label == 'Moderate Risk'" class="yellow-font">{{ score_interpretation_label }}</b>
            
            <b v-if="score_interpretation_label == 'High Risk'" class="red-font">{{ score_interpretation_label }}</b>

            </h6>

            <v-row fluid class="card-footer pl-3 mt-3">
              <v-row no-gutters>
                <v-col cols="12" sm="12" class="mb-3">
                  <h6>
                    Please add your email to check results of your completed assessment.
                  </h6>
                </v-col>

                <v-row no-gutters>
                  <v-col cols="8" sm="5">
                    <v-text-field
                      id="email"
                      class="pr-2"
                      v-model="email"
                      label="Email"
                      required
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                  <v-col cols="4" sm="4" class="ml-3">
                    <v-btn
                      style="height: 38px;"
                      id="submitEmailButton"
                      color="success"
                      class="mr-4"
                      ref="kt_store_submit"
                      @click="submitEmail()"
                    >
                      Submit
                    </v-btn>
                  </v-col>
                </v-row>
              </v-row>
              
            </v-row>
          </div>


          <div class="example" v-else-if="show_register_page">
            <v-form id="kt_reg_form" ref="reg_form" v-model="valid_reg" lazy-validation>

            <v-row no-gutters>
              <v-col cols="12" sm="6">
                <v-text-field 
                  v-model="user.first_name"
                  :rules="firstNameRules"
                  label="First Name"
                  required
                  dense
                  outlined
                  class="pr-1"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  class="pl-1"
                  v-model="user.last_name"
                  :rules="lastNameRules"
                  label="Last Name"
                  required
                  dense
                  outlined
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row no-gutters>
              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="user.email"
                  label="Email"
                  :rules="emailRules"
                  required
                  disabled
                  class="pr-1"
                  outlined
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="2">
                <v-select
                  class="pl-1"
                  v-model="user.dialing_code"
                  :items="dial_dode_list"
                  label="Dialing code"
                  outlined
                  dense
                >
                </v-select>
              </v-col>
              <v-col cols="12" sm="4">
                <v-text-field
                  class="pl-1"
                  
                  v-model="user.phone"
                  label="Phone number"
                  outlined
                  dense
                  required
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row no-gutters>
              <v-col cols="12" sm="6">
                <v-text-field
                  class="pr-1 pl-1"
                  v-model="user.password"
                  label="Password"
                  outlined
                  type="password"
                  :rules="passwordRule"
                  dense
                  autocomplete="off"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  class="pr-1 pl-1"
                  v-model="user.confirm_password"
                  label="Confirm Password"
                  type="password"
                  :rules="confirmPasswordRule"
                  outlined
                  dense
                  required
                  autocomplete="off"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row no-gutters>
              <v-col cols="12" sm="6">
                <v-text-field
                  class="pr-1 pl-1"
                  v-model="user.company_name"
                  label="Company"
                  :rules="companyRules"
                  required
                  dense
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                    class="pr-1 pl-1"
                    v-model="user.address"
                    label="Address 1"
                    outlined
                    dense
                    required
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row no-gutters>
              <v-col cols="12" sm="3">
                <v-select
                  class="pr-1 pl-1"
                  v-model="user.country_id"
                  :items="country_list"
                  label="Country"
                  outlined
                  dense
                  @change="changeStates"
                >
                </v-select>
              </v-col>
              <v-col cols="12" sm="3">
                <v-select
                    class="pr-1 pl-1"
                    v-model="user.state_id"
                    :items="state_list"
                    label="State"
                    outlined
                    dense
                  >
                </v-select>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                    class="pr-1 pl-1"
                    v-model="user.suite_number"
                    label="Address 2"
                    outlined
                    dense
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="12" sm="3">
                  <v-text-field
                    class="pr-1 pl-1"
                    v-model="user.city"
                    label="City"
                    outlined
                    dense
                    required
                  ></v-text-field>
              </v-col>
              <v-col cols="12" sm="3">
                  <v-text-field
                    class="pr-1 pl-1"
                    v-model="user.zipcode"
                    label="Zip Code"
                    outlined
                    dense
                    required
                  ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-select
                  class="pr-1 pl-1"
                  v-model="user.emp_count"
                  :items="emp_count_list"
                  label="No. of Employees"
                  :rules="empCountRules"
                  outlined
                  dense
                  required
                >
                </v-select>
              </v-col>
            </v-row>

            <v-row no-gutters>
              <v-col cols="12" sm="6">
                <v-select
                  class="pr-1 pl-1"
                  v-model="user.revenue"
                  :items="revenue_list"
                  label="Revenue"
                  outlined
                  dense
                  required
                >
                </v-select>
              </v-col>
              <v-col cols="12" sm="6">
                <v-select
                  class="pr-1 pl-1"
                  v-model="user.org_type"
                  :items="small_org_type_list"
                  label="Organization Type"
                  outlined
                  dense
                >
                </v-select>
              </v-col>
            </v-row>

            <v-row no-gutters>
              <v-col cols="12" sm="6">
                <v-select
                  class="pr-1 pl-1"
                  v-model="user.industry"
                  :items="industry_list"
                  label="Industry"
                  outlined
                  dense
                  required
                >
                </v-select>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  class="pr-1 pl-1"
                  v-model="user.ein"
                  label="EIN Number(optional)"
                  outlined
                  dense
                  required
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="12" sm="6" v-if="user.industry == 'Other (Please enter into box)'">
                <v-text-field
                  class="pr-1 pl-1"
                  v-model="user.other_industry"
                  label="Other Industry"
                  :rules="otherIndustryRules"
                  outlined
                  dense
                  required
                ></v-text-field>
              </v-col>
            </v-row>

            <v-container fluid class="card-footer pl-0 pt-3">
              <v-btn
                id="submitRegisterButton"
                :disabled="!valid_reg"
                color="success"
                class="mr-4"
                ref="kt_store_submit"
                @click="validateRegisterForm"
              >
                Register
              </v-btn>
            </v-container>
          </v-form>
          </div>

        </form>
      </div>
    </div>
    <!--end::Contacs-->


    <!-- Assessment Dialogue -->
    <div class="text-center">
      <v-dialog v-model="assessment_dialog" width="70%">
        <v-card>
          <v-card-title class="headline grey lighten-2">
            {{assessment_name}}
          </v-card-title>

          <v-card-text class="text-h6 pa-5">
            <span>
              You have completed <b>{{assessment_name}}</b> with 
  
              <b v-if="score_interpretation_label == 'Low Risk'" class="green-font">{{ score_interpretation_label }}</b>
              
              <b v-if="score_interpretation_label == 'Moderate Risk'" class="yellow-font">{{ score_interpretation_label }}</b>
              
              <b v-if="score_interpretation_label == 'High Risk'" class="red-font">{{ score_interpretation_label }}</b>
            </span>
            <br>

            <span v-if="old_user"> You have already registered account in Askcoda with this email: <b>{{email}}</b> and the assessment has been assigned to it. 
            <br>
            </span>
            <span v-if="old_user">
            Please login with the same email to see Assessment Results.
            </span>

            <span v-if="!old_user">
            Please register into Askcoda to see Assessment Results.
            </span>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions v-if="old_user">
            <v-spacer></v-spacer>
            <v-btn color="success" @click="redirectToLogin()">
              Login
            </v-btn>
            <v-btn color="error" @click="redirectToAssessment()">
              Cancel
            </v-btn>
          </v-card-actions>

          <v-card-actions v-else>
            <v-spacer></v-spacer>
            <v-btn color="success" @click="redirectToRegister()">
              Register
            </v-btn>
            <v-btn color="error" @click="redirectToAssessment()">
              Cancel
            </v-btn>
          </v-card-actions>

        </v-card>
      </v-dialog>
    </div>
    <!-- Assessment Dialogue -->

    <!-- Register Success Dialogue -->
    <div class="text-center">
      <v-dialog v-model="reg_success_dialog" width="55%">
        <v-card>
          <v-card-title class="headline grey lighten-2">
            Registration Success
          </v-card-title>

          <v-card-text class="text-h6 pa-5">
            <span> You have successfully registered account in Askcoda with this email: <b>{{email}}</b>
            </span>
            <br>
            <span>
              You have completed <b>{{assessment_name}}</b> with 
  
              <b v-if="score_interpretation_label == 'Low Risk'" class="green-font">{{ score_interpretation_label }}</b>
              
              <b v-if="score_interpretation_label == 'Moderate Risk'" class="yellow-font">{{ score_interpretation_label }}</b>
              
              <b v-if="score_interpretation_label == 'High Risk'" class="red-font">{{ score_interpretation_label }}</b>
            </span>
            <br>
            <span>
            Please login with the same email to see Assessment Results.
            </span>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="success" @click="redirectToLogin()">
              Login
            </v-btn>
            <v-btn color="error" @click="redirectToAssessment()">
              Cancel
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
    <!-- Register Success Dialogue -->

  </div>
</template>

<script>
import $ from 'jquery';
import { mapGetters } from "vuex";
import Vuetify from "vuetify";
import axios from "axios";
import EventBus from "@/core/services/store/event-bus";

import {
  GUEST_STORE_ANSWER,
  GUEST_STORE_ASSESSMENT_RESULT,
  GUEST_CANCEL_ASSESSMENT_EXIT,
  //GUEST_SAVE_ASSESSMENT_EXIT,

} from "@/core/services/store/guestassessment.module";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Swal from "sweetalert2";
import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";

export default {
  name: "GuestAssessmentFill",
  vuetify: new Vuetify(),
  data() {
    return {
      user: {
        first_name: "",
        last_name: "",
        email: "",
        dialing_code:"",
        phone:"",
        password: "",
        confirm_password: "",
        company_name:"",
        address:"",
        suite_number:"",
        country_id: "",
        state_id: "",
        zipcode: "",
        city: "",
        emp_count: "",
        revenue: "",
        industry: "",
        other_industry:"",
        org_type: "",
        ein: "",
      },
     
      country_list: [],
      state_list: [],
      dial_dode_list: ["+1","+91","+44"],
      emp_count_list: ["<25","26-50","51-100","101-250","250+"],
      revenue_list: [],
      small_org_type_list: [],
      industry_list:[
                      "Agriculture, Forestry, Fishing and Hunting",
                      "Arts, Entertainment, and Recreation",
                      "Broadcasting",
                      "Computer and Electronics Manufacturing",
                      "Daycare and Other Childcare",
                      "Education - College, University, Adult, and Professional Education",
                      "Education - Primary/Secondary (K-12)",
                      "Education - Other",
                      "Energy, Utilities",
                      "Finance and Insurance",
                      "Government and Public Administration",
                      "Healthcare",
                      "Hotel and Lodging Services",
                      "Industrials (Manufacturing, Construction, Mining)",
                      "Industrials - Other",
                      "Law and Legal Services",
                      "Military",
                      "Professional Services (including Consulting)",
                      "Publishing, Media",
                      "Real Estate, Rental and Leasing",
                      "Religious Services",
                      "Restaurants and Dining",
                      "Retail",
                      "Scientific and Technical Services",
                      "Social Services and Social Assistance",
                      "Software and Technology",
                      "Telecommunications",
                      "Transportation, Logistics, and Warehousing",
                      "Wholesale",
                      "Other (Please enter into box)"
                    ],
      passwordRule: [
        v => !!v || "Password is required",
        v =>
          (v && v.length >= 6) ||
          "Password must be greater than or equal 6 characters"
      ],
      confirmPasswordRule: [
        v =>
          (v && v == this.user.password) ||
          "Password and Confirm Password is not same"
      ],
      firstNameRules: [
        v => !!v || "First Name is required",
        v =>
          (v && v.length <= 25) || "First Name must be less than 25 characters"
      ],
      lastNameRules: [
        v => !!v || "Last Name is required",
        v =>
          (v && v.length <= 25) || "Last Name must be less than 25 characters"
      ],
      emailRules: [
        v => !!v || "E-mail is required",
        v =>
          /^\b[A-Z0-9._%-]+@[A-Z0-9.-]+\.[A-Z]{2,4}\b$/i.test(v) ||
          "E-mail must be valid"
      ],
      otherIndustryRules: [
        v => !!v || "Other Industry is required"
      ],
      phoneRules: [
        v => !!v || "Phone number is required",
        v =>
          (v && v.length <= 15) ||
          "Phone number must be less than 15 characters",
        v =>
          (v && v.length >= 6) ||
          "Phone number must be greater than 5 characters"
      ],
      companyRules: [
        v => !!v || "Company is required"
      ],
      stateRules: [
        v => !!v || "State is required"
      ],
      cityRules: [
        v => !!v || "City is required"
      ],
      addressRules: [
        v => !!v || "Address is required"
      ],
      zipRules: [
        v => !!v || "Zip Code is required",
        v => (v && v.length >= 5) || "Zip Code must be greater than 5 character"
      ],
      empCountRules: [
        v => !!v || "Please select number of employees"
      ],
      user_token: "",
      assessment_name: "",
      page_header: "",
      is_NA: 0,
      question: "",
      option1: "",
      option2: "",
      option3: "",
      option_value: "",
      section_id: "",
      valid: true,
      valid_reg: true,
      disable_btn: false,
      max_progress: 100,
      current_progress: 0,
      selected_option: "",
      order: 1,
      number_of_questions: 0,
      progress_interval: 0,
      assessment_score: 0,
      view_submit: false,
      view_ok_next: false,
      pre_assessment_info: "",
      post_assessment_info: "",
      score_interpretation_label: "",
      complete_assessment: false,
      is_first_question: false,
      recommandations: [],
      feedback_points: [],
      is_informational: 0,
      email:"",
      assessment_dialog: false,
      old_user: false,
      show_register_page: false,
      reg_success_dialog:false
    };
  },
  computed: {
    ...mapGetters(["layoutConfig"])
  },
  watch: {
    selected_option: function(newVal) {
      if(newVal && this.selected_option.next_question_id == 0){
        this.view_submit = true;
      }else{
        this.view_submit = false;
      }
    }
  },
  mounted() {
    let context = this;
    if (this.$route.params.id) {
      context.startAssessment(-1);
      context.is_first_question = true;
      //context.setUserToken();
    }

    EventBus.$on("STORE_GUEST_ASSESSMENT_SCORE", function(payLoad) {
      context.view_ok_next = true;
      // context.post_assessment_info = payLoad.result.post_assessment_info;
      context.post_assessment_info = payLoad.result.score_interpretation_text;
      context.feedback_points = payLoad.result.feedback_points;
      context.score_interpretation_label =
        payLoad.result.score_interpretation_label;
      context.recommandations = payLoad.result.recommandations;
      context.complete_assessment = true;
    });

    EventBus.$on("STORE_GUEST_ASSESSMENT_ANSWER", function(payLoad) {
      if (payLoad) {
        // context.assessment_score += parseInt(
        //   context.selected_option.option_score
        // );
        context.is_first_question = false;
        context.getNextQuestion();
      } else {
        Swal.fire("Error", "Error in storing Assessment.", "error");
      }
    });

    // set the tab from previous
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Assessment" }]);

    context.getCountries();
    context.getRevenueLevels();
    context.getOrgTypes();

    const reg_form = KTUtil.getById("kt_reg_form");

    context.fv = formValidation(reg_form, {});
  },
  destroyed() {
    localStorage.removeItem("builderTab");
  },
  methods: {
    setUserToken(){
      let context = this;
      context.user_token = Math.random().toString(36).substr(2)+"_"+Date.now();
      console.log(context.user_token);
    },
    cancelAndExitAssessment() {
      let context = this;
      Swal.fire({
        title: "Do you want to cancel the assessment and exit?",
        showCancelButton: true,
        confirmButtonText: "Cancel and Exit"
      }).then(result => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          this.$store
            .dispatch(GUEST_CANCEL_ASSESSMENT_EXIT, {
              assessment_master_id: this.$route.params.id,
              user_token: context.user_token
            })
            .then(() => {
              Swal.fire(
                "Saved!",
                "Assessment cancelled successfully",
                "success"
              );
            })
            .then(() => {
              context.redirectToAssessmentList();
            });
        }
      });
    },
    startAssessment(order) {
      let context = this;
      if(order == -1){
        context.setUserToken();
      }
      axios({
        method: "get",
        url: "guest-assessment-questionnaire",
        baseURL: process.env.VUE_APP_ACBACK_URL,
        params: { order: order, assessment_master_id: this.$route.params.id, user_token: context.user_token }
      }).then(
        function(result) {
          if (result.data.assessment_name) {
            context.assessment_name = result.data.assessment_name;
            context.page_header = result.data.assessment_name;
          }
          if (result.data.assessment_question) {
            context.question_id = result.data.assessment_question.id;
            context.question = result.data.assessment_question.question_text;
            context.number_of_questions =
              result.data.assessment_question.number_of_questions;
            context.order = parseInt(result.data.assessment_question.order) + 1;
            context.is_NA = result.data.assessment_question.is_NA;
            context.option1 = result.data.assessment_question.options[0];
            context.option2 = result.data.assessment_question.options[1];

            if(result.data.assessment_question.is_NA == 1){
              context.option3 = result.data.assessment_question.options[2];
            }
            
            context.section_id = result.data.assessment_question.section_id;
            context.progress_interval = (
              100 / context.number_of_questions
            ).toFixed(2);
            context.pre_assessment_info = "";
          } else if (result.data.assessment_pre_info) {
            context.pre_assessment_info = result.data.assessment_pre_info;
          } else {
            // context.$router.go(-1); //go back to list
            Swal.fire(result.data.error, "Question not found.", "error");
          }
        },
        function() {
          context.redirectToAssessmentList(); //go back to list
          Swal.fire("Error", "Assessment not found!", "error");
        }
      );
    },
    validateAnswer() {
      let context = this;
      if(context.is_informational == 1){
        if (!context.option_value) {
          Swal.fire("Error", "Please add an answer.", "error");
        } else {
          context.storeAssessmentAnswer();
        }
      }else{
        if (!context.selected_option) {
          Swal.fire("Error", "Please select one option.", "error");
        } else {
          context.storeAssessmentAnswer();
        }
      }
    },
    submitAssessment() {
      let context = this;
        if(context.is_informational == 1){
          if (!context.option_value) {
            Swal.fire("Error", "Please add an answer.", "error");
          } else {
              context.storeAssessmentAnswer();
             var qryObj = {
              assessment_master_id: this.$route.params.id,
              section_id: context.section_id,
              attempt_id: 1,
              user_token: context.user_token
            };
            this.$store.dispatch(GUEST_STORE_ASSESSMENT_RESULT, qryObj).then(() => {
              context.view_ok_next = true;
            });
          }
        }else{
          if (!context.selected_option) {
            Swal.fire("Error", "Please select one option.", "error");
          } else {
              context.storeAssessmentAnswer();
              var qryObj = {
                assessment_master_id: this.$route.params.id,
                section_id: context.section_id,
                attempt_id: 1,
                user_token: context.user_token
              };
              this.$store.dispatch(GUEST_STORE_ASSESSMENT_RESULT, qryObj).then(() => {
                context.view_ok_next = true;
              });
          }
        }
    },
    postInfoViewed() {
      let context = this;
      context.redirectToAssessmentList();
    },
    storeAssessmentAnswer() {
      let context = this;
      if(context.is_informational == 1){
        var qryObj = {
          assessment_master_id: this.$route.params.id,
          question_id: context.question_id,
          option_id: context.option1.id,
          option_value: context.option_value,
          section_id: context.section_id,
          is_informational: context.is_informational,
          user_token: context.user_token
        };
      }else{
        var qryObj = {
          assessment_master_id: this.$route.params.id,
          question_id: context.question_id,
          option_id: context.selected_option.id,
          section_id: context.section_id,
          is_informational: context.is_informational,
          user_token: context.user_token
        };
      }
      this.$store.dispatch(GUEST_STORE_ANSWER, qryObj);
      context.option_value = ""; 
    },
    getNextQuestion() {
      let context = this;
      var next_question = 0;

      if(context.is_informational == 1){
        next_question = context.option1.next_question_id;
      }else{
        next_question = context.selected_option.next_question_id;
      }  

      // context.current_progress += parseInt(context.progress_interval);
      axios({
        method: "get",
        url: "guest-assessment-questionnaire",
        baseURL: process.env.VUE_APP_ACBACK_URL,
        params: {
            question_id: next_question,
            assessment_master_id: this.$route.params.id,
            user_token: context.user_token
        }
      }).then(
        function(result) {
          if (result.data.assessment_question) {

            if((context.is_NA == 1) && 
              (context.option1.next_question_id == 0 ||
              context.option2.next_question_id == 0 ||
              context.option3.next_question_id == 0)){
              context.view_submit = true;
            }else if((context.is_NA == 0) && 
              (context.option1.next_question_id == 0 ||
              context.option2.next_question_id == 0)){
              context.view_submit = true;
            }

            context.selected_option = "";

            context.question_id = result.data.assessment_question.id;
            context.question = result.data.assessment_question.question_text;
            context.order = parseInt(result.data.assessment_question.order) + 1;
            context.is_informational = result.data.assessment_question.is_informational;
            if(result.data.assessment_question.is_informational == 0){

              context.is_NA = result.data.assessment_question.is_NA;
              context.option1 = result.data.assessment_question.options[0];
              context.option2 = result.data.assessment_question.options[1];

              if(result.data.assessment_question.is_NA == 1){
                context.option3 = result.data.assessment_question.options[2];
              }
              
            }else{
              context.option1 = result.data.assessment_question.options[0];
              context.selected_option = result.data.assessment_question.options[0];
              if(context.selected_option.next_question_id == 0){
                context.view_submit = true;
              }else{
                context.view_submit = false;
              }
            }
            
            context.section_id = result.data.assessment_question.section_id;
            context.current_progress = parseInt(
              context.progress_interval * context.order
            );
            
          } else {
            // Swal.fire(result.data.error, "Question fetch error", "error");
          }
        },
        function() {
          // Swal.fire("Error", "Assessment Question not found!", "error");
          // context.assessment_score -= parseInt(
          //   context.selected_option.option_score
          // );
        }
      );
    },
    redirectToAssessmentList() {
      let context = this;
      context.$router.go();
    },
    redirectToLogin() {
      let context = this;
      context.assessment_dialog = false;
      context.$router.push({ name: "login" });
    },
    redirectToRegister() {
      let context = this;
      context.page_header = "Register Details";
      context.user.email = context.email;
      context.assessment_dialog = false;
      context.show_register_page = true;
    },
    redirectToAssessment(){
      let context = this;
      context.assessment_dialog = false;
      context.$router.go();
    },
    submitEmail(){
      let context = this;

      axios({
        method: "post",
        url: "check-email-guest-assessment",
        baseURL: process.env.VUE_APP_ACBACK_URL,
        params: { email: context.email , 
                  user_token : context.user_token,
                  assessment_master_id : this.$route.params.id
                }
      }).then(
          function(result) {
            if(result.data.status == 0){
              Swal.fire("Error", result.data.message, "error");
            }else{
              if(result.data.user_exist == "Yes"){
                context.old_user = true;
              }else{
                context.old_user = false;
              }
              $('#email').attr('disabled',true);
              $('#submitEmailButton').attr('disabled',true);
              context.assessment_dialog = true;
            }
          },
          function() {
            Swal.fire("Error", "Error!", "error");
          }
      );
    },
    getCountries(){
      let context = this;
      axios({
        method: "post",
        url: "get-countries",
        baseURL: process.env.VUE_APP_ACAUTH_URL
      }).then(
        function(result) {
          if (result.data.countries) {
            result.data.countries.forEach(country => {
              context.country_list.push({
                text: country.name,
                value: country.id
              });
            });
          } else {
            Swal.fire("Error", "Countries not found!", "error");
          }
        },
        function() {
          Swal.fire("Error", "Countries not found!", "error");
        }
      );
    },
    getOrgTypes(){
      let context = this;
      axios({
        method: "post",
        url: "get-organization-types-by-company",
        baseURL: process.env.VUE_APP_ACBACK_URL,
        params: {
            company_type: "Small Enterprise"
        }
      }).then(
        function(result) {
          if (result.data.organization_types) {
            result.data.organization_types.forEach(org_type => {     
                context.small_org_type_list.push({ text: org_type.type, value: org_type.type });
            });
          }else{
            Swal.fire("Error", "Organization Types not found!", "error");
          }
        },
        function() {
          Swal.fire("Error", "Organization Types not found!", "error");
        }
      );
    },
    getRevenueLevels(){
      let context = this;
      axios({
        method: "post",
        url: "get-revenue-levels",
        baseURL: process.env.VUE_APP_ACAUTH_URL
      }).then(
        function(result) {
          if (result.data.revenue_list) {
            result.data.revenue_list.forEach(revenue_list => {
              context.revenue_list.push({
                text: revenue_list.min_revenue+' - '+revenue_list.max_revenue,
                value: revenue_list.min_revenue+'-'+revenue_list.max_revenue,
              });
            });
          } else {
            Swal.fire("Error", "Revenue Levels not found!", "error");
          }
        },
        function() {
          Swal.fire("Error", "Revenue Levels not found!", "error");
        }
      );
    },
    changeStates() {
      let context = this;
      context.state_list = [];
      axios({
        method: "get",
        url: "get-states",
        baseURL: process.env.VUE_APP_ACBACK_URL,
        params: {
            country_id: context.user.country_id
        }
      }).then(
        function(result) {
          if (result.data.states) {
            result.data.states.forEach(state => {
              context.state_list.push({ text: state.name, value: state.id });
            });
          } else {
            Swal.fire("Error", "States not found!", "error");
          }
        },
        function() {
          Swal.fire("Error", "States not found!", "error");
        }
      );
    },
    validateRegisterForm() {
      this.fv.on("core.form.invalid", () => {
        Swal.fire({
          title: "",
          text: "Please, provide correct data!",
          icon: "error",
          confirmButtonClass: "btn btn-secondary",
          heightAuto: false
        });
      });
      if (this.$refs.reg_form.validate()) {
        this.register();
      } else {
        this.snackbar = true;
      }
    },
    register() {
      var context = this;
      var formData = new FormData();

      //User's Data
      formData.append("user_token", context.user_token);
      formData.append("assessment_master_id", this.$route.params.id);
      formData.append("subscription", "free");
      formData.append("first_name", context.user.first_name);
      formData.append("last_name", context.user.last_name);
      formData.append("email", context.user.email);
      formData.append("dialing_code", context.user.dialing_code);
      formData.append("phone_number", context.user.phone);
      formData.append("password", context.user.password);
      formData.append("confirm_password", context.user.confirm_password);

      //Company's Data
      formData.append("company_name", context.user.company_name);
      formData.append("address", context.user.address);
      formData.append("suite_number", context.user.suite_number);
      formData.append("country_id", context.user.country_id);
      formData.append("state_id", context.user.state_id);
      formData.append("zipcode", context.user.zipcode);
      formData.append("city", context.user.city);
      formData.append("no_of_employee", context.user.emp_count);
      formData.append("revenue", context.user.revenue);
      formData.append("industry", context.user.industry);
      formData.append("org_type", context.user.org_type);
      formData.append("ein_no", context.user.ein);

      if(context.user.industry == 'Other (Please enter into box)'){
        formData.append("other_industry", context.user.other_industry);
      }

      axios({
        method: "post",
        url: "se-register",
        baseURL: process.env.VUE_APP_ACBACK_URL,
        data: formData,
        headers: {
          "Content-type": "application/x-www-form-urlencoded"
        }
      }).then(
        function(result) {
          if(result.data.status == 0){
            Swal.fire("Error", result.data.message, "error");
          }else{
            //Swal.fire("Success", result.data.message, "success");
            //context.redirectToAssessmentList();
            $('#submitRegisterButton').attr('disabled',true);
            context.reg_success_dialog = true;
          }
        },
        function() {
          Swal.fire("Error", "Error in user register", "error");
        }
      );
    },
  }
};
</script>
